"use client";

import type { NewsletterModel } from "@/app/(sites)/_api/newsletter/newsletter.types";
import { NewsletterSignUp } from "@/components/Newsletter/NewsletterSignUp/NewsletterSignUp.component";
import { useNewsletterSignup } from "@/components/Newsletter/NewsletterSignUp/use-newsletter-signup.hook";
import { NewsletterSignUpFailedBox } from "@/components/Newsletter/NewsletterSignUpFailedBox/NewsletterSignUpFailedBox.component";
import { NewsletterSignUpForm } from "@/components/Newsletter/NewsletterSignUpForm/NewsletterSignUpForm.component";
import { NewsletterSignUpImage } from "@/components/Newsletter/NewsletterSignUpImage/NewsletterSignUpImage.component";
import { NewsletterSignUpSuccessBox } from "@/components/Newsletter/NewsletterSignUpSuccessBox/NewsletterSignUpSuccessBox.component";
import { SwitchFetchable } from "@/components/SwitchFetchable/SwitchFetchable.component";
import type { ConversionEvent } from "@/hooks/tracking/kilkaya/klikaya.types";
import type { Fetchable } from "@/services/utilities/fetchable";
import { initial, isPending } from "@/services/utilities/fetchable";
import { clsx } from "clsx";
type WatchNewsletterSignUpProps = {
  readonly className?: string;
  readonly conversionEventTags?: ConversionEvent;
  readonly initialSubmissionState?: Fetchable;
  readonly model: NewsletterModel;
};
export function WatchNewsletterSignUp({
  className,
  conversionEventTags,
  initialSubmissionState = initial(),
  model
}: WatchNewsletterSignUpProps) {
  const {
    reset,
    submissionState: submission,
    submit
  } = useNewsletterSignup(initialSubmissionState);
  return <NewsletterSignUp className={clsx("bg-whisper text-charcoal", className)} data-sentry-element="NewsletterSignUp" data-sentry-component="WatchNewsletterSignUp" data-sentry-source-file="WatchNewsletterSignUp.component.tsx">
      <div className="col-span-full xl:col-span-7">
        <SwitchFetchable fetchable={submission} renderDefault={() => <NewsletterSignUpForm isPending={isPending(submission)} model={model} onSubmit={submit} renderImage={() => <NewsletterSignUpImage className="bg-primary brightness-90" />} />} renderFailed={() => <NewsletterSignUpFailedBox reset={reset} />} renderSucceeded={() => <NewsletterSignUpSuccessBox conversionEvent={conversionEventTags} />} data-sentry-element="SwitchFetchable" data-sentry-source-file="WatchNewsletterSignUp.component.tsx" />
      </div>

      <div className="hidden xl:col-span-5 xl:block">
        <NewsletterSignUpImage className="bg-primary brightness-90" data-sentry-element="NewsletterSignUpImage" data-sentry-source-file="WatchNewsletterSignUp.component.tsx" />
      </div>
    </NewsletterSignUp>;
}