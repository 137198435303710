"use client";

import { Translation } from "../Translation/Translation.component";
import type { Translations } from "../Translation/translation.types";
import { SimpleArticleListItem } from "./components/SimpleArticleListItem.component";
import type { SimpleArticleListModel } from "./models/simple-article-list-model.types";
import { useSnowplowRecommendationInViewTracking } from "@/hooks/tracking/snowplow/use-snowplow-recommendation-in-view-tracking.hook";
import { clsx } from "clsx";
type SimpleArticleListProps = {
  readonly className?: string;
  readonly title: Translations;
  readonly appearance?: "ordered" | "unordered";
  readonly items: ReadonlyArray<SimpleArticleListModel>;
  readonly trackingId?: null | string;
  readonly orderedNumberClassname?: string;
};
export function SimpleArticleList({
  appearance = "unordered",
  className,
  items,
  orderedNumberClassname = "text-5xl lg:text-6xl",
  title,
  trackingId
}: SimpleArticleListProps) {
  const {
    ref
  } = useSnowplowRecommendationInViewTracking({
    articleIds: items.map(article => article.articleId),
    trackingId: trackingId ?? null
  });
  return <section className={clsx(className, "h-full")} ref={ref} data-sentry-component="SimpleArticleList" data-sentry-source-file="SimpleArticleList.component.tsx">
      <h1 className="mb-4 flex flex-col font-theme-bold uppercase">
        <Translation model={title} data-sentry-element="Translation" data-sentry-source-file="SimpleArticleList.component.tsx" />
      </h1>
      <div className="space-y-4 font-heading font-theme-bold">
        {items.map((article, index) => <SimpleArticleListItem appearance={appearance} article={article} index={index} key={article.articleId} orderedNumberClassname={orderedNumberClassname} position={`art${index + 1}`} trackingId={trackingId} />)}
      </div>
    </section>;
}